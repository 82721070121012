import { introduce, urls } from "../../data/profile"
import Avatar from "../Avatar"

const URLButton = ({ url, text }) => {
  return (
    <a
      href={url}
      className="inline-block px-2 font-bold rounded-md text-primary-content bg-primary hover:bg-primary-focus "
      target="_blank"
      rel="noreferrer"
    >
      <button>{text}</button>
    </a>
  )
}

function Info(props) {
  return (
    <div className="flex items-end justify-center w-full p-4 py-12 ">
      <div className="w-full ">
        <div className="flex justify-center circle-grad">
          <Avatar />
        </div>
        <div className="flex flex-col justify-center w-full pl-2 mt-5 text-center">
          <div className="items-baseline ">
            <h2 className="text-6xl font-bold text-primary">안지영</h2>{" "}
          </div>
          <p className="pt-2 mt-2">
            {introduce.map((line, i) => {
              return (
                <span key={i}>
                  {line}
                  <br />
                </span>
              )
            })}
          </p>
          <div className="mt-4 space-x-2">
            <URLButton url={urls.github} text="Github" />
            <URLButton url={urls.blog} text="Blog" />
          </div>
        </div>
      </div>
    </div>
  )
}

function Profile(props) {
  return (
    <div className="w-full py-4 mt-24" id="about">
      <Info />
    </div>
  )
}

export default Profile
