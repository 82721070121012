const skills = [
  {
    category: "Frontend ✨",
    skills: [
      "React, Vue",
      "Redux, Recoil, React-Query ",
      "TailwindCSS, Bootstrap",
      "JavaScript, TypeScript",
    ],
  },
  {
    category: "Backend",
    skills: ["Node.js, Express.js, Spring"],
  },
  {
    category: "Database",
    skills: ["MySQL, MongoDB"],
  },
  {
    category: "Deployment",
    skills: ["AWS, Netlify, Heroku", "Docker, Jenkins"],
  },
  {
    category: "Tool",
    skills: ["Git, Github, Jira, Figma"],
  },
]
export default skills
