const introduce = [
  "현재는 SAP BW, SAC 시스템 운영중입니다.",
  "새로운 시스템에 흥미를 느끼며 즐겁게 일하는 사회 초년생🐣 ",
  "하고싶은 것이 많아서 고민..",
  "",
  "마지막 업데이트 날짜 : 2023.09.26 ",
]

const urls = {
  github: "https://github.com/camiyoung",
  blog: "https://anji0.tistory.com/",
  email: "anji6978@naver.com",
}

export { introduce, urls }
