const education = [
  {
    name: "삼성 청년 SW 아카데미 수료(상위 5%)",
    period: "2022.01 - 2022.12",
    description: "최우수(상위5%) 수료",
  },
  {
    name: "서울시립대학교 컴퓨터과학부 ",
    period: "2016.03 - 2021.02",
    description: "컴퓨터과학부",
  },
]

const awards = [
  {
    name: "삼성 청년 SW아카데미 - 관통, 공통, 자율, 특화 프로젝트 ( 각각 1등 ) 및 전국 결선 입상",
    period: "2022.05~2022.12",
  },
  // {
  //   name: "삼성 청년 SW아카데미 특화 프로젝트 우수상 (1등)",
  //   period: "2022.10.07",
  // },
  // {
  //   name: "삼성 청년 SW아카데미 공통 프로젝트 우수상 (1등)",
  //   period: "2022.08.19",
  // },
  // {
  //   name: "삼성 청년 SW아카데미 관통 프로젝트 최우수상 (1등)",
  //   period: "2022.05.27",
  // },
]
const careers = [
  {
    name: "CJ OliveNetworks - 식품IT",
    period: "2023.01 ~ ",
  },
  {
    name: "서울시립대학교  -  행정 인턴",
    period: "2020.12 ~ 2021.11 ",
  },
]

export { education, awards, careers }
