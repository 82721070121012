import React from "react"
import skillData from "../../data/skills"
function Skills(props) {
  return (
    <div className="p-5 skill-container" id="skills">
      <h2 className="section-title">SKILLS</h2>
      <div className="grid gap-4 p-4 mt-2 gap-y-7 md:grid-cols-2 lg:grid-cols-3">
        {skillData.map((skill, i) => {
          return (
            <div key={i}>
              <h3 className="pl-3 text-2xl font-semibold rounded-full bg-accent py-0.5 text-accent-content text-center shadow-md">
                {skill.category}
              </h3>
              <ul className="pl-6 mt-1 leading-7 list-disc">
                {skill.skills &&
                  skill.skills.map((item) => <li key={item}>{item}</li>)}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Skills
