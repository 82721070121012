import { unified } from "unified"
import markdown from "remark-parse"
import remark2rehype from "remark-rehype"
import html from "rehype-stringify"

export default function mdTohtmlConverter(text) {
  const html_text = unified()
    .use(markdown)
    .use(remark2rehype)
    .use(html)
    .processSync(text)
    .toString()
  console.log(html_text)
  return html_text
}
