import Project from "./Project"
import projects from "../../data/projects"

function Projects(props) {
  return (
    <div className="w-full p-5 " id="projects">
      <h2 className="section-title">PROJECTS</h2>
      <ul className="flex flex-wrap content-center justify-center mt-12 space-y-7 circle-grad2">
        {projects.map((item, i) => (
          <Project item={item} key={i} />
        ))}
      </ul>
    </div>
  )
}

export default Projects
