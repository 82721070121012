import React from "react"
import post from "../data/posts"
import mdTohtmlConverter from "../utils/mdTohtml"
import parse from "html-react-parser"
const ProjectDetail = ({ item: project, setProject }) => {
  const result = mdTohtmlConverter(post)

  return (
    <div className="w-full mb-1 border-t-4 border-dashed">
      <div className="">
        {/* <div className="mt-4 text-center ">내용 수정중 🛠</div> */}
        <section className="flex justify-center m-8 space-x-2">
          {/* <button className="p-1 px-2 border-2 rounded-lg text-base-content bg-base-300 border-base-content">
            Github Repository
          </button>
          <button className="p-1 px-2 border-2 rounded-lg text-base-content bg-base-300 border-base-content">
            프로젝트 소개 영상
          </button> */}
        </section>
        <article className="min-w-full p-4 pt-0 prose prose-zinc">
          {/* <Viewer initialValue={result.value} /> */}
          {parse(result)}
        </article>
      </div>
    </div>
  )
}

export default ProjectDetail
