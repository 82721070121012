const projects = [
  {
    id: 1,
    title: " 집에서 운동중 ",
    description: "팀 프로젝트 (6명) - Frontend 담당 ",
    github: "https://github.com/camiyoung/ZipZong",
    skills: ["react", "redux", "tailwind"],
    emoji: "🏋🏻‍♂️",
    period: "2022.07.05 - 2022.08.19",
    brief:
      "컴포넌트간의 상태 관리를 위해서 상태 관리 라이브러리인 Redux를 도입하여 사용해보았습니다. 실시간으로 변경되는 UI를 제작하면서 React 컴포넌트의 라이프사이클을 활용한 코드를 작성해 보면서 React 활용방법에 대한 숙련도를 높일 수 있었습니다.",
    detail: {
      task: {
        brief: "내가 개발한 내용 간략 소개 ",
        list: ["기능1", "기능2"],
      },
      result: "",
    },
  },
  {
    id: 2,
    title: " 드림멍즈",
    description: "팀 프로젝트 (6명) - Frontend 담당 ",
    github: "https://github.com/camiyoung/DreamMungz",
    skills: ["react", "typescript", "react-query", "recoil", "tailwind"],
    emoji: "🐕",
    period: "2022.08.22 - 2022.10.07",
    brief:
      "Typescript,  React-query, Recoil, 를 새롭게 학습하여 적용해본 프로젝트입니다. 사용전과 후를 비교해보며 기술들의 장단점을 알아 볼 수 있었습니다. 재사용 가능한 컴포넌트 제작을 위해서 설계단계부터 확장성을 고려하였고, 의도대로 재사용 가능한 컴포넌트를 제작했습니다. ",
    detail: {
      task: {
        brief: "내가 개발한 내용 간략 소개 ",
        list: ["기능1", "기능2"],
      },
      result: "",
    },
  },
  {
    id: 5,
    title: " 편한 집 찾기 ",
    description: "팀 프로젝트 (2명) - Frontend, Backend 담당",
    github: "https://github.com/SSAFY7-16-7/HappyHouse",
    skills: ["vue", "vuex", "springboot", "mysql"],
    emoji: "🏡",
    period: "2022.05.19 - 2022.05.26",
    brief:
      "Vue와 Spring을 이용하여 Frontend, Backend 모두를 경험해본 프로젝트입니다. 이 프로젝트를 통해서 SPA프레임워크인 Vue와 React를 모두 경험해 보았고, 두 프레임워크의 차이점을 알게 되었습니다. ",
    detail: {
      task: {
        brief: "내가 개발한 내용 간략 소개 ",
        list: ["기능1", "기능2"],
      },
      result: "",
    },
  },
  {
    id: 4,
    title: " 실시간 채팅 프로젝트  ",
    description: " 개인 프로젝트  - Frontend, Backend 담당",
    github: "https://github.com/camiyoung/chat-with-react",
    skills: ["react", "Node.js", "Express.js", "Socket.io"],
    emoji: "💬",
    period: "2021. 06",
    brief:
      "Socket.io를 활용하여 채팅 기능을 구현한 프로젝트입니다. React와 Node.js를 활용하여 Frontend와 Backend 모두 Javascirpt를 사용해서 구현하였습니다. ",
    detail: {
      task: {
        brief: "내가 개발한 내용 간략 소개 ",
        list: ["기능1", "기능2"],
      },
      result: "",
    },
  },
  {
    id: 3,
    title: " Starry Night ",
    description: "팀 프로젝트 (6명) - Unity 담당 ",
    github: "https://github.com/camiyoung/StarryNight",
    skills: ["unity", "react"],
    emoji: "🌟",
    period: "2022. 10 .11 - 2022. 11. 25",
    brief:
      "Unity를 이용하여 진행한 프로젝트입니다. PC조작과 VR 기기로도 조작이 가능한 메타버스를 구현하였습니다. ",
    detail: {
      task: {
        brief: "내가 개발한 내용 간략 소개 ",
        list: ["기능1", "기능2"],
      },
      result: "",
    },
  },
]
export default projects
