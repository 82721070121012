import { useEffect, useRef, useState } from "react"
import Twemoji from "react-twemoji"
import ProjectDetail from "../../pages/ProjectDetail"
function Project({ item }) {
  const inputRef = useRef()

  const closeProjectDetail = () => {
    inputRef.current.checked = false
  }

  return (
    <>
      <li className="w-full rounded-lg shadow-md collapse ">
        <input type="checkbox" className="peer" ref={inputRef} />
        <div className="flex justify-between w-full p-4 collapse-title bg-primary text-primary-content peer-checked:bg-accent-focus peer-checked:text-accent-content">
          <div className="flex flex-col justify-center w-[95%]">
            <div className="flex items-center justify-between ">
              <div className="mb-2">
                <div className="flex flex-col w-full text-base ">
                  <h1 className="mr-3 text-3xl font-extrabold ">
                    {item.title}
                  </h1>
                  <p className="mt-2 text-sm ">
                    [{item.description} / {item.period}]
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-2 space-x-1">
              <p>{item.brief}</p>
            </div>
            <div className="flex flex-wrap mt-2 space-x-1">
              {item.skills.map((skill, i) => {
                return (
                  <span
                    className="text-sm font-semibold bg-base-300 text-base-content p-0.5 rounded-md capitalize px-1 mb-1"
                    key={i}
                  >
                    {skill}
                  </span>
                )
              })}
            </div>
          </div>

          <Twemoji
            options={{ className: "twemoji" }}
            className="items-center justify-center hidden w-24 md:flex"
          >
            <span>{item.emoji}</span>
          </Twemoji>
        </div>
        <div className="w-full collapse-content bg-primary text-primary-content peer-checked:bg-accent-focus peer-checked:text-accent-content">
          <ProjectDetail item={item} />
          <div
            onClick={closeProjectDetail}
            className="py-1 mt-3 text-center rounded-full shadow-sm cursor-pointer bg-base-200/50 text-base-content "
          >
            상세 설명 닫기
          </div>
        </div>
      </li>
    </>
  )
}

export default Project
