import { useEffect, useState } from "react"
import ThemeButton from "./components/ThemeButton"
import Navbar from "./components/navbar/Navbar"

const { Outlet } = require("react-router")
const { default: Footer } = require("./components/footer/Footer")

function App() {
  return (
    <div className="relative flex flex-col justify-center m-auto">
      {/* <Navbar /> */}
      <div className="max-w-screen-lg m-auto overflow-scroll no-scrollbar">
        <ThemeButton />
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}

export default App
