import React from "react"

const navlist = [
  { title: "About Me", link: "about" },
  { title: "Skills", link: "skills" },
  { title: "Projects", link: "projects" },
  { title: "Awards", link: "awards" },
  { title: "Education", link: "education" },
]

function Navbar() {
  return (
    <header className=" h-[50px]  w-full   fixed top-0 z-20 bg-base-100 text-center items-center justify-center hidden md:flex shadow-md">
      <div className="hidden space-x-10 md:flex bg-base-100 rounded-box">
        {navlist.map((item) => {
          return (
            <nav key={item.link}>
              <a href={`#${item.link}`} className="link link-primary">
                {item.title}
              </a>
            </nav>
          )
        })}
      </div>
    </header>
  )
}

export default Navbar
