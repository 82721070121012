import { education, awards, careers } from "../../data/experiences"

function ExperienceList({ list }) {
  return (
    <div className="w-full ">
      <ul className="mt-2 space-y-3">
        {list.map((item, idx) => (
          <li className="items-baseline space-x-1" key={idx}>
            <div className="font-semibold">{item.name}</div>
            <div className="text-sm">{item.period}</div>
          </li>
        ))}
      </ul>
    </div>
  )
}

const Experience = (props) => (
  <div className="w-full p-5 mt-8 ">
    <div className="" id="careers">
      <h2 className="section-title"> EXPERIENCE</h2>
      <ExperienceList list={careers} />
    </div>
    <div className="" id="awards">
      <h2 className="section-title">AWARDS</h2>
      <ExperienceList list={awards} />
    </div>
    <div className="" id="education">
      <h2 className="section-title">EDUCATION</h2>
      <ExperienceList list={education} />
    </div>
  </div>
)

export default Experience
