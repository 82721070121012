// const post = `
// ## 프로젝트를 통해 배운것
// Lorem ipsum dolor sit amet **consectetur** adipisicing elit.Incidunt,magni odio? Alias, odio? Minus,alias repellendus amet undepraesentium atque blanditiis aut nulla qui perspiciatis voluptatibusnostrumeaque. Quod, eius?
// > sdfsdfsdfsdf

// ## 프로젝트를 통해 배운것
// Lorem ipsum dolor sit amet consectetur adipisicing elit.Incidunt,magni odio? Alias, odio? Minus,alias repellendus amet undepraesentium atque blanditiis aut nulla qui perspiciatis voluptatibusnostrumeaque. Quod, eius?
// ## 개발한 전체 기능
// Lorem ipsum dolor sit amet consectetur adipisicing

// - aaa
// - bbb
//   - sdf	sdfsdf

// - dsfsdf

// `

const post = `

### 업데이트 예정입니다.
현재 프로젝트 설명은 프로필의 Github에서 확인 가능합니다.

`

export default post
